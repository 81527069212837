<template>
  <section id="view_error_404">
    <Error404 />
  </section>
</template>

<script>
//import Error404 from '@/components/404/404.vue'
import { defineAsyncComponent } from "vue";
export default {
  name: "View Error 404",
  components: {
    Error404: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Error404" */ "@/components/404/404.vue"
      )
    ),
  },
};
</script>